import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'item', 'bubble', 'notificationBadge' ]

  static values = {
    on: String,
    addClass: String,
    removeClass: String
  }

  itemTargetConnected(element) {
    this.personalize(element)
  }

  bubbleTargetConnected(element) {
    if(element.dataset['commentsUnseenBy'].split(',').includes(this.onValue)) {
      element.classList.remove('hidden')
      element.classList.add('tw-relative')
    } else {
      element.classList.remove('tw-relative')
      element.classList.add('hidden')
    }
  }

  notificationBadgeTargetConnected(element) {
    const activityLabels = { 'created': 'Neu', 'updated': 'Update' }
    const unseenBy = JSON.parse(element.dataset['taskUnseenBy'])
    const unseenByCurrentUser = unseenBy.find(userTask => userTask.user_id === this.onValue)

    if(unseenByCurrentUser) {
      element.classList.remove('tw-hidden')
      element.textContent = activityLabels[unseenByCurrentUser.activity]
    } else {
      element.classList.add('tw-hidden')
    }
  }

  personalize(element) {
    if (element.dataset['personalizeOn'] !== this.onValue) return

    this.personalizeClass(element)
  }

  personalizeClass(element) {
    this.personalizeRemoveClass(element)
    this.personalizeAddClass(element)
  }

  personalizeAddClass(element) {
    const klass = element.dataset['personalizeAddClass'] || this.addClassValue
    if (klass === '') return

    element.classList.add(...klass.split(' '))
  }

  personalizeRemoveClass(element) {
    const klass = element.dataset['personalizeRemoveClass'] || this.removeClassValue
    if (klass === '') return

    element.classList.remove(...klass.split(' '))
  }
}
