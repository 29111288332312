import { Controller } from "@hotwired/stimulus"
import jQuery from 'jquery'
import gql from 'graphql-tag'
import { apolloAutocompleteClient as apolloClient } from '../global/apollo'
import {hubspotCompanies, zendeskOrganizations} from "@queries/autocomplete";

// Need a redo after we remove zendesk
export const zendeskQuery = gql`
  query($id: ID!) {
    zendeskContact(id: $id) {
      addressCity
      addressStreet
      addressStreetNumber
      addressZipCode
      id
      name
    }
  }
`;

export const hubspotQuery = gql`
  query($id: ID!) {
    hubspotCompany(id: $id) {
      addressCity
      addressStreet
      addressStreetNumber
      addressZipCode
      id
      name
    }
  }
`;

export default class extends Controller {
  static outlets = ['crm-select2-suggest'];
  static targets = [
    "name",
    "selectionSource",
    "addressZipCode",
    "addressCity",
    "addressStreet",
    "addressStreetNumber",
    "crmSource",
    "info"
  ];

  static values = {
    crmSourceIsHubspot: { type: Boolean, default: false }
  }

  connect() {
    document.addEventListener('change', this.onChange.bind(this));
    jQuery(document).on('change', this.onChange.bind(this)); // select2 triggers jquery events
  }

  disconnect() {
    document.removeEventListener('change', this.onChange.bind(this))
    jQuery(document).off('change'); // select2 triggers jquery events
  }

  onChange(event) {
    if (event.target !== this.selectionSourceTarget) {
      return;
    }

    const value = this.selectionSourceTarget.value;

    if (!value && !this.crmSourceIsHubspotValue) {
      return;
    }
    this.prefill(value);
  }

  prefill(companyId) {
    const variables = { id: companyId };

    const query = this.crmSourceIsHubspotValue ? hubspotQuery : zendeskQuery

    apolloClient
      .query({query: query, variables })
      .then((response) => {
        return this.crmSourceIsHubspotValue ? response.data.hubspotCompany : response.data.zendeskContact;
      })
      .then(this.fillFields.bind(this));
  }

  fillFields(contact) {
    this.setSelect(this.addressZipCodeTarget, contact.addressZipCode);

    this.setSelect(this.addressCityTarget, contact.addressCity);

    this.setSelect(this.addressStreetTarget, contact.addressStreet);

    this.setInput(this.addressStreetNumberTarget, contact.addressStreetNumber);

    this.setInput(this.nameTarget, contact.name);
  }

  setInput(element, value, setWith = (input, value) => { input.value = value; }) {
    element.disabled = false;

    setWith(element, value)

    const changeEvent = new Event('change');
    element.dispatchEvent(changeEvent);
  }

  setSelect(element, value) {
    const val = value ? value : '';

    this.setInput(element, val, (input, value) => {
      const option = new Option(value, value, false, true);
      input.append(option);
    });
  }

  toggleCrmSource() {
    this.crmSourceIsHubspotValue = !this.crmSourceIsHubspotValue
  }

  resetField(element) {
    if (jQuery(element).hasClass('select2-hidden-accessible')) {
      this.selectionSourceTarget.value = null
      jQuery(element).val(null).trigger('change');
    } else {
      element.value = '';
    }
  }

  crmSourceIsHubspotValueChanged() {
    this.setSelect(this.addressZipCodeTarget, '');
    this.setSelect(this.addressCityTarget, 'PLZ auswählen');
    this.setSelect(this.addressStreetTarget, 'Ort auswählen');
    this.setInput(this.addressStreetNumberTarget, '');
    this.setInput(this.nameTarget, '');
    this.resetField(this.selectionSourceTarget)
    const queryName = this.crmSourceIsHubspotValue ? 'hubspotCompanies' : 'zendeskOrganizations'
    this.crmSelect2SuggestOutlet.setQuery(queryName)
    if (this.crmSourceIsHubspotValue) {
      this.selectionSourceTarget.dataset.select2SuggestQuery = "hubspotCompanies"
      this.selectionSourceTarget.labels[0].innerHTML = "Hubspot Unternehmen"
      this.infoTarget.innerHTML = "Bitte wähle zuerst ein Unternehmen aus Hubspot aus, um den Auftrag erstellen zu können."
    } else {
      this.selectionSourceTarget.dataset.select2SuggestQuery = "zendeskOrganizations"
      this.selectionSourceTarget.labels[0].innerHTML = "Zendesk Sell-Unternehmen"
      this.infoTarget.innerHTML = "Bitte wähle zuerst ein Unternehmen aus Zendesk Sell aus, um den Auftrag erstellen zu können."
    }
  }
}
