import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]

  increment() {
    const currentValue = parseInt(this.inputTarget.value) || 0;
    const maxValue = parseInt(this.inputTarget.max) || 1000;

    if (currentValue < maxValue) {
      this.inputTarget.value = currentValue + 1;
    }
  }

  decrement() {
    const currentValue = parseInt(this.inputTarget.value) || 0;
    const minValue = parseInt(this.inputTarget.min) || 0;

    if (currentValue > minValue) {
      this.inputTarget.value = currentValue - 1;
    }
  }
}
