/**
  * TODO: This needs a refactoring. Maybe its possible, to get rid of this controller and use rails/view_comoponents only.
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["am", "eis", "sbe", "amlimit", "statusListAM", "statusTextAM", "statusTextDisable", "statusTextAMDisable"];

  connect() {
    this.getInitialState();
  }

  toggle(event) {
    /**
     * ! The following line is needed to have the same correct value in the checkbox and the hidden input.
     * <input name="order_configuration[automatic_matching_enabled]" type="hidden" value="xxxx" autocomplete="off">
     * <input class="tw-hidden tw-peer" [...] type="checkbox" value="xxxx" [...]>
    */
    event.target.previousSibling.value = event.target.checked ? 'true' : 'false'

    if (event.target == this.amTarget) {
      Array.from(this.statusTextDisableTargets).forEach(target => {
        target.classList.toggle("tw-hidden", !this.amTarget.checked);
      });

      this.statusTextAMDisableTarget.classList.remove('tw-hidden')

      this.toggleCheckboxStates();
      this.updateStatusMessage();
    }
  }

  getInitialState() {
    const isAMChecked = this.amTarget.checked;
    const targets = [this.eisTarget, this.sbeTarget];

    targets.forEach(target => {
      const parentElement = target.parentElement.parentElement;
      parentElement.classList.toggle('tw-pointer-events-none', isAMChecked);
    });

    Array.from(this.statusTextDisableTargets).filter(target => target.nodeName === "P").forEach(target => {
      if (isAMChecked) {
        target.classList.remove("tw-hidden");
      }
    });

    this.toggleLockClasses(this.eisTarget, isAMChecked,
      ['tw-text-white', 'tw-translate-x-5'], ['tw-hidden']);

    this.toggleLockClasses(this.sbeTarget, isAMChecked,
      ['tw-text-[#D1D5DB]', 'tw-left-2'], ['tw-hidden']);

    this.amlimitTarget.querySelector('.input-container').classList.toggle('tw-bg-gray-100', !isAMChecked);

    this.amlimitTarget.querySelectorAll('button, input').forEach(element => {
      element.disabled = !isAMChecked;
    })
  }

  toggleCheckboxStates() {
    const isAMChecked = this.amTarget.checked;
    const hiddenInputs = this.element.querySelectorAll('[data-controller="automatic-matching"] input[type="hidden"]');
    const targets = [this.eisTarget, this.sbeTarget];

    this.eisTarget.disabled = isAMChecked;
    this.sbeTarget.disabled = isAMChecked;

    this.eisTarget.checked = true
    this.sbeTarget.checked = false

    hiddenInputs.forEach(hiddenInput => {
      hiddenInput.value = hiddenInput.nextElementSibling.checked ? 'true' : 'false';
    })

    Array.from(this.statusTextDisableTargets).filter(target => target.nodeName === "P").forEach(target => {
      if (isAMChecked) {
        target.classList.remove("tw-hidden");
      }
    });

    this.toggleLockClasses(this.eisTarget, isAMChecked,
      ['tw-text-white', 'tw-translate-x-5'], ['tw-hidden']);

    this.toggleLockClasses(this.sbeTarget, isAMChecked,
      ['tw-text-[#D1D5DB]', 'tw-left-2'], ['tw-hidden']);

    this.amlimitTarget.querySelector('.input-container').classList.toggle('tw-bg-gray-100', !isAMChecked);

    this.amlimitTarget.querySelectorAll('button, input').forEach(element => {
      element.disabled = !isAMChecked;
    })

    targets.forEach(target => {
      const parentElement = target.parentElement.parentElement;
      parentElement.classList.toggle('tw-pointer-events-none', isAMChecked);
    });
  }

  toggleLockClasses(element, isChecked, addClasses, removeClasses) {
    const sibling = element.nextElementSibling?.nextElementSibling;
    const lastChild = element.parentNode.lastElementChild;

    if (sibling) sibling.classList.toggle('tw-hidden', isChecked);
    lastChild.classList.toggle('tw-hidden', isChecked);

    addClasses.forEach(className => lastChild.classList.toggle(className, isChecked));
    removeClasses.forEach(className => lastChild.classList.toggle(className, !isChecked));
  }

  updateStatusMessage() {
    const isChecked = this.amTarget.checked;

    const statusMessages = {
      am: isChecked
        ? "Automatisches Matching aktiviert"
        : "Automatisches Matching für alle Schichten deaktiviert",

      list: isChecked
        ? `
          <ul class="tw-ml-12 tw-mt-4">
            <li class="tw-list-disc">Schichten können nun für das automatische Matching auf der Seite “Schichten” aktiviert werden</li>
            <li class="tw-list-disc">Einbuchen in Schichten (EiS) wird für den gesamten Auftrag aktiviert</li>
            <li class="tw-list-disc">Buchen von Einsatzzeiten (SbE) für den gesamten Auftrag deaktiviert</li>
          </ul>`
        : `
          <ul class="tw-ml-12 tw-mt-4">
            <li class="tw-list-disc">Schichten können nicht mehr für das automatische Matching aktiviert werden</li>
            <li class="tw-list-disc">Das Einbuchen in Schichten (EiS) und das Buchen von Einsatzzeiten (SbE) können jetzt für den gesamten Auftrag geändert werden</li>
          </ul>`
    };

    this.statusTextAMTarget.textContent = statusMessages.am;
    this.statusListAMTarget.innerHTML = statusMessages.list;
  }
}
