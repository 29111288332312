import { Controller } from "@hotwired/stimulus"

import jQuery from 'jquery'
import '../global/select2'

import { apolloAutocompleteClient as apolloClient } from '../global/apollo'
import * as queries from '@queries/autocomplete'
import { tryJson } from '../global/utils/try_json'

// Could be either deleted after we remove zendesk, or made into hubspot specific controller? Probably no need for it at all
export default class extends Controller {
  static values = {
    perPage: Number,
    select2Options: Object,
    query: String,
  }

  connect() {
    const perPage = this.perPage
    const dropdownParent = this.element.closest('form')
    const query = this.query

    const options = {
      language: 'de',
      dropdownParent,
      ... this.select2Options,
      ajax: {
        transport: (params, success, failure) => {
          const variables = {
            first: perPage,
            term: params.data.term || '',
            after: params.data.page ? this.lastPageInfo?.endCursor : null
          };

          apolloClient
            .query({ query, variables })
            .then(({ data }) => {
              const { nodes, pageInfo } = data.items
              this.lastPageInfo = pageInfo

              const results = nodes.map(n => ({ ...n }))

              success({
                results: results,
                pagination: {
                  more: pageInfo.hasNextPage
                }
              });
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              failure('An error occurred while fetching data');
            });
        },
        processResults: (data) => {
          return data; // Data is already in the correct format
        },
        cache: true
      }
    };

    const placeholder = this.element.querySelector("option[value='']");

    if (placeholder) {
      if (!Object.prototype.hasOwnProperty.call(options, 'allowClear')) {
        options.allowClear = true;
      }
      if (!Object.prototype.hasOwnProperty.call(options, 'placeholder')) {
        options.placeholder = placeholder.innerText;
      }
    }

    this.$select2 = jQuery(this.element).select2(options);
  }

  disconnect() {
    this.$select2.select2('destroy')
  }

  get perPage() {
    if (this.hasPerPageValue) {
      return this.perPageValue
    }

    const i = Number.parseInt(this.data.get('per-page'), 10)

    if (!Number.isNaN(i)) {
      return i
    }

    return 10
  }

  get select2Options() {
    if (this.hasSelect2OptionsValue) {
      return this.select2OptionsValue
    }

    tryJson(this.data.get('options'))
  }

  get query() {
    if (this.hasQueryValue) {
      return queries[this.queryValue]
    }

    return queries[this.data.get('query')]
  }

  setQuery(queryName) {
    this.queryValue= queryName
  }

  queryValueChanged() {
    if (this.$select2 === undefined) {
      return
    }

    let options = this.$select2.data('select2').options.options;

    options.ajax.transport = (params, success, failure) => {
      const variables = {
        first: this.perPage,
        term: params.data.term || '',
        after: params.data.page ? this.lastPageInfo?.endCursor : null
      };

      const query = this.query
      apolloClient
        .query({ query, variables })
        .then(({ data }) => {
          const { nodes, pageInfo } = data.items
          this.lastPageInfo = pageInfo

          const results = nodes.map(n => ({ ...n }))

          success({
            results: results,
            pagination: {
              more: pageInfo.hasNextPage
            }
          });
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          failure('An error occurred while fetching data');
        });
    };

    // Destroy and reinitialize Select2 with the updated options
    this.$select2.select2('destroy');
    this.$select2.select2(options);
  }
}
