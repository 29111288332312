import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "checkbox", "date", "input"]

  connect() {
    const isChecked = this.element.querySelector('input[type="checkbox"]').checked;
    this.toggle(isChecked);
  }

  toggle(event) {
    const checked = event.target ? event.target.checked : event;

    if (checked) {
      this.toggleTarget.classList.remove("tw-hidden");
      this.enableInputs();
    } else {
      this.toggleTarget.classList.add("tw-hidden");
      this.clearCheckboxes();
      this.resetDateField();
      this.disableInputs();
    }
  }

  clearCheckboxes() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
  }

  resetDateField() {
    if (this.hasDateTarget) {
      this.dateTarget.value = "";
    }
  }

  disableInputs() {
    this.inputTargets.forEach(input => {
      input.disabled = true;
    });
  }

  enableInputs() {
    this.inputTargets.forEach(input => {
      input.disabled = false;
    });
  }
}
